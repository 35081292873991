import React, { useEffect, useState } from 'react';
import './style.css';
import { connect } from 'react-redux';

const Test = ({ infosetting, user, loggedIn }) => {
const [searchBarQuery, setSearchBarQuery] = useState("");

    return (
        <div className='hsb-container search-bar'>
            <i class="fa-solid fa-user-tag"></i>
            <input name='search' placeholder='Adjuster or Company Name' value={searchBarQuery} onChange={({ target }) => { setSearchBarQuery(target.value); }} />
        </div>
    )
};


function mapStateToProps(state) {
    return { ...state.authentication, ...state };
}

export default connect(mapStateToProps)(Test);